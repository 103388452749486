.flexy {
  padding: 148px 0;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.flexy__animation img {
  width: auto;
  max-width: none;
}
.flexy__animation svg {
  width: auto !important;
  max-width: none;
}

@media (min-width: 600px) {
  .flexy {
    padding: 120px 0;
  }

  .flexy__animation svg {
    height: 350px !important;
  }
}

@media (min-width: 1100px) {
  .flexy {
    padding: 160px 0;
  }

  .flexy__animation svg {
    height: 500px !important;
  }
}

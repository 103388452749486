.why-us-list {}
.why-us-list__term {
  font-family: "Spoof Trial Black", sans-serif;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: 0.01em;
  text-align: left;
  padding-bottom: 8px;
  padding-right: 40px;
}
.why-us-list__description {
  font-family: "TT Hoves Light", sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 24px;
}
.why-us-list__description:last-of-type {
  padding-bottom: 0;
}
@media (min-width: 1000px) {
  .why-us-list {
    display: grid;
    grid-template-columns: 456px 1fr;
    grid-gap: 20px;
  }
  .why-us-list__description {
    padding-bottom: 76px;
  }
}

.text {}

.text_style_1 {
  font-family: "TT Hoves Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.text_style_1 b {
   font-family: "TT Hoves Bold", sans-serif;
 }

.text_style_2 {
  font-family: "TT Hoves Light", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.text_style_2 b {
  font-family: "TT Hoves Bold", sans-serif;
}

.text_style_3 {
  font-family: "TT Hoves Light", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.text_style_3 b {
  font-family: "TT Hoves Bold", sans-serif;
}

.text_style_4 {
  font-family: "Spoof Trial Regular", sans-serif;
  font-size: 28px;
  line-height: 32px;
}
.text_style_4 b {
  font-family: "Spoof Trial Black", sans-serif;
}

@media (min-width: 650px) {
  .text_style_2 {
    font-size: 20px;
    line-height: 28px;
  }
}

.path {
  --color-default: #EFE8E1;
  --color-activated: #000;
  --color-current: var(--color-default);
  --counter-size: 38px;
  --border-width: 2px;
  --line-left: 25px;
  --line-height: 0;
  box-sizing: border-box;
  padding: 0 20px;
  counter-reset: my-awesome-counter;
  position: relative;
}
.path::before,
.path::after {
  content: "";
  display: block;
  width: 2px;
  position: absolute;
  top: 0;
  left: var(--line-left);
  max-height: 100%;
}
.path::before {
  background: var(--color-default);
  height: 100%;
  z-index: 1001;
}
.path::after {
  height: var(--line-height);
  z-index: 1002;
  background: var(--color-activated);
}
.path__item {
  --activated-height: 0;
  counter-increment: my-awesome-counter;
  box-sizing: border-box;
  margin-left: 5px;
  padding-left: 30px;
  padding-bottom: 49px;
  position: relative;
}
.path__item:last-of-type {
  padding-bottom: 0;
}
.path__item::before {
  box-sizing: border-box;
  content: counter(my-awesome-counter);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1010;
  top: 0;
  left: calc((var(--counter-size) / -2));
  font-family: "Spoof Trial Black", sans-serif;
  font-feature-settings: 'ss06' on, 'zero' on;
  font-size: 22px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0;
  width: var(--counter-size);
  height: var(--counter-size);
  border-radius: 50%;
  background: #FFFBF4;
  border: var(--border-width) solid  var(--color-current);
  color:  var(--color-current);
}
.path__item:last-of-type::after {
  content: "";
  display: block;
  background: #FFFBF4;
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -2px;
  z-index: 1003;
}
.path__animation {
  width: 300px;
  margin-bottom: 20px;
  position: relative;
}
.path__animation svg {
  width: 100%;
}

.path__item_activated {
  --color-current: var(--color-activated);
}
.path__item_activated::before {
  transition: color .2s ease-in-out, border-color .2s ease-in-out;
}

@media (min-width: 650px) {
  .path {
    --line-left: calc(340px + 18px);
  }
  .path__item {
    margin-left: 340px;
    position: relative;
    padding-bottom: 124px;
  }
  .path__animation {
    position: absolute;
    top: 0;
    left: -340px;
  }
}

@media (min-width: 1100px) {
  .path {
    --counter-size: 88px;
    --line-left: calc(50% - 1px);
    margin-top: 20px;
  }
  .path__item {
    margin-left: 50%;
    max-width: 570px;
    padding-left: 120px;
  }
  .path__item:last-of-type {
    padding-bottom: 60px;
  }
  .path__item::before {
    font-size: 24px;
    line-height: 24px;
  }
  .path__animation {
    width: 400px;
    left: -520px;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.hero__heading {
  font-family: "Spoof Trial Medium", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  padding: 0 48px 17px;
  max-width: 80%;
}
.hero__text{
  font-family: "Spoof Trial Medium", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  padding: 38px 20px 40px;
  box-sizing: border-box;
}
.hero__points {
}

@media (min-width: 650px) {
  .hero__heading {
    font-family: "Spoof Trial Black", sans-serif;
    font-weight: 800;
    letter-spacing: -0.02em;

    font-size: 56px;
    line-height: 58px;

    padding-top: 20px;
    padding-bottom: 20px;
  }
  .hero__text {
    text-align: center;
    max-width: 1135px;
    padding: 18px 40px 74px;
    font-size: 28px;
    line-height: 40px;
  }
  .hero__points {
    flex-direction: row-reverse;
  }
}

@media (min-width: 1100px) {
  .hero__heading {
    font-size: min(6.8vw, 12vh, 126px);
    line-height: min(6.9vw, 12vh, 132px);
    padding-bottom: 24px;
  }
}

/* Hero Animation */
.hero__animation {
  min-width: 100%;
  width: 100%;
  height: auto;
  max-height: 100%;
}
.hero__animation img,
.hero__animation svg {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: 450px) {
  .hero__animation {
    min-width: unset;
    width: auto;
    max-width: 100%;
  }
}
@media (min-width: 450px) and (max-width: 1136px) {
  .hero__animation svg {
    width: 85%;
    height: auto;
  }
  @media (orientation: landscape) {
    .hero__animation svg {
      max-height: 40vh;
    }
  }
}
@media (min-width: 1200px) {
  .hero__animation svg {
    width: auto;
    height: 408px;
  }
  @media (orientation: landscape) {
    .hero__animation svg {
      max-height: 47vh;
    }
  }
}

.muffins-earth {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
}
.muffins-earth__animation {
  width: 768px;
  height: 462px;
}
.muffins-earth__copyrights {
  display: none;
}

@media (min-width: 650px) {
  .muffins-earth__animation {
    width: 1097px;
    height: 660px;
  }
  .muffins-earth__copyrights {
    display: block;
    font-family: "TT Hoves Regular", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
    background-color: #000;
    color: #FFFBF4;
    border: 1px solid #FFFBF4;
    border-radius: 24px;
    padding: 8px 24px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
  }
}

@media (min-width: 1100px) {
  .muffins-earth__animation {
    width: 1844px;
    height: 826px;
    margin-bottom: -15px;
  }
  .muffins-earth__copyrights {
    bottom: 40px;
  }
}
